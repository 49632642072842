import React from "react"
import { signin } from '../../actions/AuthAction'
import { setAuthToken } from '../../actions/_auth'

class Login extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      submitting:     false,
      email:          '',
      password:       '',
      error_message:  '',
    }
  }

  render() {
    return(
      <div>
        <h1 className="text-center my-5">Welcome</h1>
        <div className="card p-3" style={{width: "28rem", margin: "auto"}}>
          <div className="card-body">
            {this.errorMessage()}
            <form onSubmit={this.onSubmit}>
              <div className="mb-2">
                <label htmlFor="email" className="form-label">Your Email Address</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={this.state.email}
                  onChange={this.onChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">Password</label>
                <input
                  type="password"
                  className="form-control"
                  autoComplete="current-password"
                  id="password"
                  name="password"
                  value={this.state.password}
                  onChange={this.onChange}
                />
              </div>
              <div>
                <button className="btn btn-primary" disabled={this.state.submitting}>LOGIN</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }

  errorMessage = () => {
    if(this.state.error_message && this.state.error_message !== '') {
      return(
        <div className="alert alert-danger" role="alert">
          {this.state.error_message}
        </div>
      )
    }
  }

  onChange = (e) => {
    var new_state = {...this.state}
    new_state[e.target.name] = e.target.value
    this.setState(new_state)
  }

  onSubmit = (e) => {
    e.preventDefault()
    this.setState({
      submitting: true,
      error_message: '',
    })
    
    var data = {
      email:    this.state.email,
      password: this.state.password
    }

    signin(data, {
      success:  this.onSuccess,
      error:    this.onError
    })
  }

  onSuccess = (res) => {
    this.setState({
      submitting: false,
      error_message: ''
    })

    setAuthToken(res.token)
    const windowGlobal = typeof window !== 'undefined' && window
    windowGlobal.location = "/app/"
  }

  onError = (message) => {
    this.setState({
      submitting: false,
      error_message: message
    })
  }

}

Login.propTypes = {}
export default Login