import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Signin from '../../components/auth/signin'

const AuthPage = () => (
  <Layout>
    <SEO title="Signin" />
    <Signin />
  </Layout>
)

export default AuthPage